<!--
 * @Author: wangwei
 * @Date: 2020-12-29 15:56:16
 * @LastEditTime: 2023-05-10 20:21:21
 * @LastEditors: cuihaopeng cuihaopeng@slodon.cn
 * @Description: 订单支付页面
 * @FilePath: /java-pc/src/views/buy/pay.vue
-->
<template>
    <div id="pay">
        <div class="top_info">
            <div class="top_info_header">
                <div class="top_logo">
                    <div :style="`background-image:url(${configInfo.main_site_logo})`" :onerror='defaultImg'></div>
                </div>
                <div class="top_info_progress flex_row_end_center">

                    <div class="progress_item" v-if="ifcart == 'true'">
                        <div class="progress flex_row_center_center">
                            <span class="active">1</span>
                            <div class="progress_line">
                                <div :class="{ content: true, active: true }"></div>
                            </div>
                        </div>
                        <p>购物车</p>
                    </div>

                    <div class="progress_item">
                        <div class="progress flex_row_center_center">
                            <span class="active">{{ ifcart == 'true' ? 2 : 1 }}</span>
                            <div class="progress_line">
                                <div :class="{ content: true, active: true, current: false }"></div>
                            </div>
                        </div>
                        <p style="margin-left: -11px">确认订单</p>
                    </div>

                    <div class="progress_item">
                        <div class="progress flex_row_center_center">
                            <span class="active">{{ ifcart == 'true' ? 3 : 2 }}</span>
                            <div class="progress_line">
                                <div :class="{ content: true, active: payComplete }"></div>
                            </div>
                        </div>
                        <p>付款</p>
                    </div>

                    <div class="progress_item" style="margin-left: -24px">
                        <div class="progress flex_row_end_center">
                            <span :class="{ active: payComplete }">{{ ifcart == 'true' ? 4 : 3 }}</span>
                        </div>
                        <p>支付成功</p>
                    </div>
                </div>
            </div>
            <div class="top_info_text flex_row_start_center">
                <span>收货地址：</span>
                <div class="">
                    {{ pay_info.data.receiveAddress?pay_info.data.receiveAddress:'--' }}
                </div>
            </div>
            <div class="top_info_text flex_row_between_center">
                <div>
                    <span>收货人：</span>
                    <span> {{ pay_info.data.receiverName?pay_info.data.receiverName:'-' }}&nbsp;{{ pay_info.data.receiverMobile?pay_info.data.receiverMobile:'-' }}</span>
                </div>
                <div class="price">
                    <span>应付金额：</span>
                    <span class="red_text">{{ pay_info.data.orderAmount }}</span>
                    <span>元</span>
                </div>
            </div>
        </div>
        <div class="pay_line main2 flex-col">
            <div class="layer3 flex-row">
                <span class="word14">支付方式</span>
            </div>
            <template v-for="(item, index) in payMethodList" :key="index">
                <div class="layer4 flex-row">
                    <template v-if="item.payMethod == 'CREDIT' && item.payMethodList.length">
                        <template v-if="memberInfo.memberType == 3 && memberInfo.enterpriseMaster == 1">
                            <span class="word15">{{ item.payMethodName }}：</span>
                            <div class="word16 flex_row_start_center" v-for="(sub, idx) in item.payMethodList" :key="idx"
                                @click="methodChange(index, idx)">
                                <i class="iconfont iconziyuan12" v-if="clickIdx == index && (idx + index) == metIdx"></i>
                                <i class="circle1" v-else></i>
                                <span class="txt5">{{ sub.payMethodName }}</span>
                            </div>
                        </template>
                    </template>
                    <template v-else-if="item.payMethodList.length">
                        <span class="word15">{{ item.payMethodName }}：</span>
                        <div class="word16 flex_row_start_center" v-for="(sub, idx) in item.payMethodList" :key="idx"
                            @click="methodChange(index, idx)">
                            <i class="iconfont iconziyuan12" v-if="clickIdx == index && (idx + index) == metIdx"></i>
                            <i class="circle1" v-else></i>
                            <span class="txt5">{{ sub.payMethodName }}</span>
                        </div>
                    </template>
                </div>
                <!-- 企业网银 start -->
                <view class="bank_list" v-if="item.payMethodName == '在线支付' && payShowType == '20ZJ' && bankList.data.length">
                    <view class="bank_item" :class="{ active: bankIndex == indexs }" v-for="(items, indexs) in bankList.data"
                        :key="indexs" @click="changeBank(indexs)">
                        <i class="iconfont iconziyuan12" v-if="bankIndex == indexs"></i>
                        <i class="circle1" v-else></i>
                        <view class="bank_item_logo"><img :src="items.logo" /></view>
                    </view>
                    <view class="bank_other" :class="{ active: bankIndex == bankList.data.length }"
                        @click="changeBank(bankList.data.length)">
                        <i class="iconfont iconziyuan12" v-if="bankIndex == bankList.data.length"></i>
                        <i class="circle1" v-else></i>
                        <view class="bank_account">
                            <span>其他银行:</span><input v-model="bankAccount" placeholder="请输入银行卡号" />
                        </view>
                    </view>
                </view>
                <!-- 企业网银 end -->
            </template>

            <!-- 银行汇款 start -->
            <div class="bank_credit " v-if="payShowType == 'BANK'">
                <div class="main7 flex_row_between_center" v-for="(item, index) in pay_info.data.orderList" :key="index">
                    <div class="main7_left">
                        <div class="mod2 flex-row">
                            <span class="word20">订单号：{{ item.orderSn }}</span>
                            <span class="word20" v-if="item.purchaseSn">采购单号：{{ item.purchaseSn }}</span>
                        </div>
                        <div class="con_info">
                            <div class="mod3 flex-row">
                                <span class="info9">{{ item.storeName }}汇款信息</span>
                            </div>
                            <div class="mod4 flex-row">
                                <div class="group5 flex-col">
                                    <div class="group6 flex-row">
                                        <span class="info10">待支付金额：</span>
                                        <span class="word22">¥{{ item.orderAmount.toFixed(2) }}</span>
                                    </div>
                                    <div class="group6 flex-row">
                                        <span class="info10"> 开户名称：{{ item.companyName || '--' }}</span>
                                    </div>
                                    <div class="group6 flex-row">
                                        <span class="info10">收款账户：{{ item.bankAccount || '--' }}</span>
                                        <div class="section4 flex-col"></div>
                                    </div>
                                    <div class="group6 flex-row">
                                        <span class="info10">开户银行：{{ item.bankName || '--' }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="con_notice">
                            <div class="mod3 flex-row">
                                <span class="info9">注意事项（转账必读）</span>
                            </div>
                            <div class="mod4 flex-row">
                                <div class="group5 flex-col">
                                    <div class="group6 flex-col">
                                        <span class="info10">
                                            1.公司转账汇款时请将订单号{{ item.orderSn }} 填写至汇款单中“用途/备注/摘要”等栏
                                        </span>
                                    </div>
                                    <div class="group6 flex-col">
                                        <span class="info10">
                                            2.务必保证转账金额与订单金额一致，请勿多转/少转或分次转账。
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="main7_right flex-row">
                        <span class="info13">汇款凭证：</span>
                        <div class="group9 flex-col">
                            <template v-if="!item.imgUrl">
                                <label for="upload">
                                    <span class="txt4">点击上传凭证</span>
                                </label>
                                <input type="file" id="upload" accept="image/png,image/jpeg,image/jpg"
                                    @change="uploadFile($event, item)">
                            </template>
                            <template v-else>
                                <div class="img_con flex_row_center_center">
                                    <el-image style="width:177px;height:106px;" :src="item.imgUrl"
                                        :preview-src-list="[item.imgUrl]"></el-image>
                                    <span class="el-icon-error" @click="delImg(item)"></span>
                                </div>
                            </template>

                        </div>
                    </div>
                </div>
            </div>
            <!-- 银行汇款 end -->

            <!-- 微信支付/支付宝支付 start -->
            <div class="layer5 flex-row"
                v-else-if="payShowType == 'wx' || payShowType == '42_10_ZJ' || payShowType == '42_20_ZJ'">
                <div class="left">
                    <div class="layer5_code"><img v-if="scanCode" :src="scanCode" /></div>
                    <p>使用<span>{{ payShowType == '42_20_ZJ' ? '支付宝' : '微信' }}</span>APP</p>
                    <p>扫码完成支付</p>
                    <div class="layer5_btn" @click="refreshCode">刷新</div>
                </div>
                <div class="right">
                    <img style="position:relative;top:5px;" v-if="payShowType == '42_20_ZJ'" src="@/assets/pay_ali.png" />
                    <img v-else src="@/assets/pay_wechat.png" />
                </div>
            </div>
            <!-- 微信支付/支付宝支付 end -->
        </div>

        <!-- 信用支付 start -->
        <div class="credit_pay" v-if="payShowType == 'CREDIT'">
            <div class="main3 flex-col">
                <div class="bd3 flex-col" v-for="(item, index) in pay_info.data.orderList" :key="index">
                    <div class="box3 flex-col">
                        <div class="group2 flex-row">
                            <div class="info6">订单号：{{ item.orderSn }}</div>
                            <div class="info6" v-if="item.purchaseSn">采购单号：{{ item.purchaseSn }}</div>
                        </div>
                    </div>
                    <div class="box4 flex_row_start_center">
                        <span class="word20">{{ item.storeName }}</span>
                        <div class="word21">
                            <span>应付金额：</span><span class="word22">¥{{ item.orderAmount.toFixed(2) }}</span>
                        </div>

                        <div class="word21">
                            <span>可用额度：</span><span class="word22">¥{{ item.remainAmount.toFixed(2) }}</span>
                        </div>
                        <div class="flex_row_center_center">
                            <i class="icon3 iconfont iconquerenyuanzhengqueduigoutijiaochenggongwancheng"
                                v-if="item.isSupportCredit"></i>
                            <i class="icon3 iconfont iconguanbi" v-else></i>
                            <span class="txt7">{{ item.isSupportCredit ? '可支付' : '不可支付' }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="main5 flex-col" v-if="allSuportCredit">
                <div class="section8 flex-row">
                    <span class="info9">支付密码</span>
                </div>
                <div class="section9 flex_row_start_center">
                    <div class="mod10 flex-col">
                        <div class="main66 flex-row">
                            <el-input class="password_input" placeholder="支付密码" v-model="password" show-password
                                :disabled="!isPwd ? 1 : 0" />
                        </div>
                    </div>
                    <div v-if="!isPwd" style="display: flex;">
                        <span class="word32">未设置支付密码</span>
                        <span class="info10" @click="toSetPwd">立即设置&gt;&gt;</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 信用支付 end -->

        <div class="pay_btn">
            <div :class="{ main7: true, 'flex-col': true, disable: !allSuportCredit }" @click="goPay"
                v-if="payShowType != 'wx' && payShowType != '42_10_ZJ' && payShowType != '42_20_ZJ'">
                <span class="txt9">确认</span>
            </div>
            <div class="main8 flex-row">
                <span class="txt10">剩余付款时间：</span>
                <span class="txt11">{{ literTime.hour }}:{{ literTime.minute }}:{{ literTime.second }}</span>
            </div>
        </div>

        <!-- <div class="bg">
            <div class="pay_method" v-if="hasPayMethod">
                <div v-if="!show_wx_pay">
                    <div class="balance" v-if="balance_list.data.length>0">
                        <img class="logo" :src="balance" alt />
                        <div class="balance_info">
                            <i :class="{iconfont:true, 'icon-yuanquan1':pay_info.data.balanceAvailable>=pay_info.data.needPay,'icon-duihao1':current_pay_method.data.payMethod==balance_list.data[0].payMethod,'icon-jinzhi1':pay_info.data.balanceAvailable<pay_info.data.needPay}"
                                @click="pay_info.data.balanceAvailable>pay_info.data.needPay?changePayMethod(balance_list.data[0]):''"></i>
                           
                            <span class="weight big_size">使用余额支付</span>
                            <span class="avai">
                                （可用余额：
                                <span class="weight">{{pay_info.data.balanceAvailable}}</span>元，目前需要在线支付：
                                <span class="weight">{{pay_info.data.needPay}}</span>元）余额不足？
                                <span class="weight pointer" @click="goRecharge" style="color:#168ED8">马上充值</span>
                            </span>
                        </div>
                        <div class="password" v-if="current_pay_method.data.payMethod==balance_list.data[0].payMethod">
                            <el-input class="password_input" placeholder="支付密码" v-model="password" show-password>
                            </el-input>
                            <p v-if="!memberInfo.hasPayPassword">
                                <span style="color: #FFB33A;"><i class="el-icon-warning"></i>未设置支付密码，马上去设置</span>
                                <router-link class="set_password pointer" :to="'/member/pwd/pay'">设置密码</router-link>
                            </p>
                            <p v-else>
                                <span class="forget_password pointer" @click="forgetPassword">忘记密码?</span>
                            </p>
                        </div>
                    </div>
                    <div class="balance other_pay_method" v-if="other_pay_list.data.length>0">
                        <img class="logo" :src="otherPay" alt />
                        <div class="balance_info flex">
                            <div :class="{other_pay_item:true, flex_row_around_center:true,wechat:index!=0}"
                                v-for="(item,index) in other_pay_list.data" :key="index" @click="changePayMethod(item)">
                                <i
                                    :class="{iconfont:true, 'icon-yuanquan1':current_pay_method.data.payMethod!=item.payMethod,'icon-duihao1':current_pay_method.data.payMethod==item.payMethod}"></i>
                                <img :src="item.payMethod=='alipay'?ali:wechat" alt />
                                <span>{{item.payMethodName}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="pay" @click="pay" style="cursor: pointer;">立即支付</div>
                </div>
                <div class="wx_pay_con" v-if="show_wx_pay">
                    <p class="title" @click="hideWxPay">
                        <i class="iconfont icon-ziyuan2"></i>
                        <span>选择其它支付方式</span>
                    </p>
                    <div class="qr flex_row_around_center">
                        <div class="left flex_column_start_center">
                            <img :src="wxPayQrImg" />
                            <p>
                                使用
                                <span>微信支付</span>APP
                            </p>
                            <p>扫码完成支付</p>
                            <div class="refresh" @click="refreshWxQrcode">刷新</div>
                        </div>
                        <img class="wx_png" :src="wx_pay_tip" />
                    </div>
                </div>
            </div>
            <div class="no_payMethod" v-else>
                <img src="@/assets/member/member_empty.png" alt="">
                <p>暂无可用的支付方式，平台正在紧急处理中～</p>
            </div>
        </div> -->
    </div>
</template>

<script>
import { reactive, getCurrentInstance, ref, onMounted, onUnmounted, toRefs } from "vue";
import { ElMessage, ElInput } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
    name: "Pay",
    components: {
        ElInput
    },
    setup() {
        const ifcart = ref('')
        const balance = require("../../assets/buy/balance.png");
        const otherPay = require("../../assets/buy/otherPay.png");
        const ali = require("../../assets/buy/ali.png");
        const wechat = require("../../assets/buy/wechat.png");
        const wx_pay_tip = require("../../assets/buy/wx_pay_tip.png");
        const route = useRoute();
        const router = useRouter();
        const store = useStore()
        const { proxy } = getCurrentInstance();
        const pay_method = reactive({ data: [] });
        const pay_info = reactive({ data: {} });
        const paySn = ref("");
        const current_pay_method = reactive({ data: {} });
        const balance_list = reactive({ data: [] });
        const other_pay_list = reactive({ data: [] });
        const password = ref("");
        const show_wx_pay = ref(false);
        const wxPayQrImg = ref('');//微信支付二维码
        const timer = ref(0);//定时器
        const show_detail = ref(true)
        const hasPayMethod = ref(true)
        const configInfo = ref(store.state.configInfo)
        const memberInfo = ref(store.state.memberInfo);
        const defaultImg = ref('this.src="' + require('../../assets/common_top_logo.png') + '"')
        const metIdx = ref(0)
        const payMethodList = ref([])
        const payShowType = ref('')
        const payClock = ref(0)
        const isContinue = ref(false)
        const clickIdx = ref('')
        const scanCode = ref(''); //微信、支付宝二维码
        const bankList = reactive({ data: [] }); //企业网银列表
        const bankIndex = ref(0);
        const bankAccount = ref('');
        const literTime = reactive({
            hour: '',
            minute: '',
            second: ''
        })
        const timerProcess = ref(null);
        const timerTimeout = ref(null);
        const state = reactive({
            allSuportCredit: true,
            isPwd: true,
        })
        const payComplete = ref(false)
        const isPreventClick = ref(false)
        const first_count = ref(true)
        //获取支付方式
        const payhuoqu = ref(false);
        const getPayMethod = () => {
            proxy.$get("v3/business/front/orderPay/payMethod", {
                source: "pc",
                type: 1
            }).then(res => {
                if (res.state == 200) {
                    hasPayMethod.value = res.data.length > 0 ? true : false
                    payMethodList.value = res.data
                    payShowType.value = payMethodList.value[0].payMethodList[0].payMethod
                    if (hasPayMethod.value) {
                        payMethodList.value.forEach((item,index)=>{
                            if(item.payMethodName=='线下支付'){
                                payhuoqu.value = true
                                methodChange(index, 0)
                            }
                        })
                        if(!payhuoqu.value){
                            methodChange(0, 0)

                        }
                    }
                    let hasBlankPay = false;
                    res.data.forEach(item => {
                        item.payMethodList.forEach(items => {
                            if (items.payMethod == '20ZJ') {
                                hasBlankPay = true;
                            }
                        })
                    })
                    if (hasBlankPay) {
                        getBankList();
                    }
                } else {
                    ElMessage(res.msg);
                }
            })
        };

        //切换网银
        const changeBank = (index) => {
            bankIndex.value = index;
        };

        const toSetPwd = () => {
            // let newWin = router.resolve('/member/pwd/pay?type=set')
            // window.open(newWin.href, '_blank')
            router.push('/member/pwd/pay?type=set')
        }

        //获取订单支付数据
        const getPayInfo = () => {
            let param = { paySn: route.query.paySn }
            if (route.query.orderSn) param.orderSn = route.query.orderSn
            proxy.$get("v3/business/front/orderPay/payInfo", param).then(res => {
                if (res.state == 200) {
                    pay_info.data = res.data;

                    if (first_count.value) {
                        payClock.value = res.data.remainderPayTime
                        countDownPay()
                    }

                    first_count.value = false


                    state.allSuportCredit = true
                } else if (res.state == 267) {
                    ElMessage.success(res.msg + ',2s后自动跳转订单列表');
                    //清除定时器
                    if (timer.value) {
                        clearTimeout(timer.value);
                        timer.value = 0;
                    }
                    if (timerProcess.value) {
                        clearTimeout(timerProcess.value);
                        timerProcess.value = 0;
                    }
                    setTimeout(() => {
                        router.replace({
                            path: '/member/order/list',
                        });
                    }, 2000)
                } else {
                    ElMessage(res.msg);
                }
            })
        };

        //去支付
        const pay = () => {
            if (!current_pay_method.data.payMethod) {
                ElMessage.warning("请选择支付方式");
                return;
            }
            let param = {};
            param.payType = current_pay_method.data.payType;
            param.payMethod = current_pay_method.data.payMethod;
            param.paySn = pay_info.data.paySn;

            //余额支付
            if (param.payMethod == 'balance') {
                if (password.value == "") {
                    ElMessage.warning("请输入支付密码");
                    return;
                }
                param.payPwd = password.value; //支付密码,使用余额时必传
            }

            //请求支付接口
            proxy.$post("v3/business/front/orderPay/doPay", param).then(res => {
                if (res.state == 200) {
                    if (param.payMethod == 'balance') {
                        ElMessage.success('支付成功,2s后自动跳转订单列表');
                        payComplete.value = true
                        //清除定时器
                        setTimeout(() => {
                            router.replace({
                                path: '/member/order/list',
                            });
                        }, 2000)
                    } else if (param.payMethod == 'alipay') {
                        document.write(res.data.payData);//自动提交表单数据
                    } else if (param.payMethod == 'wx') {
                        show_wx_pay.value = true;
                        wxPayQrImg.value = 'data:image/png;base64,' + res.data.payData;//微信支付二维码
                        // 定时查询是否支付成功
                        timer.value = setInterval(() => {
                            queryPayState();
                        }, 3000)
                        //两小时自动跳转订单列表
                        setTimeout(() => {
                            router.replace({
                                path: '/member/order/list',
                            });
                        }, 432000000);
                    }
                } else {
                    ElMessage(res.msg);
                }
            })
        };

        const payPwdCheck = () => {
            proxy.$get('v3/business/front/orderPay/payPwdCheck').then(res => {
                if (res.state == 200) {
                    state.isPwd = res.data
                }
            })
        }

        // 定时查询是否支付成功
        const queryPayState = () => {
            getPayInfo();
        }
        //隐藏微信支付内容
        const hideWxPay = () => {
            show_wx_pay.value = false;
        };
        //刷新微信支付二维码
        const refreshWxQrcode = () => {
            pay();
        };
        //收起展开详情
        const showDetail = () => {
            show_detail.value = !show_detail.value
        }

        //倒计时
        const countDownPay = () => {
            payClock.value--
            let stampArr = [
                parseInt(payClock.value / 60 / 60),
                parseInt(payClock.value / 60 % 60),
                parseInt(payClock.value % 60)
            ].map(i => i > 9 ? i : '0' + i)
            literTime.hour = stampArr[0]
            literTime.minute = stampArr[1]
            literTime.second = stampArr[2]

            if (payClock.value <= 0) {
                clearTimeout(timer.value)
                ElMessage('支付单已过期')
                setTimeout(() => {
                    router.replace({
                        path: '/member/order/list',
                    });
                }, 1000)
            } else {
                timer.value = setTimeout(countDownPay, 1000)
            }
        };

        const goRecharge = () => {
            router.push('/member/recharge')
        };
        const forgetPassword = () => {
            router.push('/member/pwd/reset')
        };

        //上传凭证
        const uploadFile = (e, item) => {
            let file = e.target.files[0]
            proxy.$post('v3/oss/front/upload', {
                source: 'paymentVoucher',
                file
            }, 'form').then(res => {
                if (res.state == 200) {
                    item.imgUrl = res.data.url
                    item.imgPath = res.data.path
                }
            })
        };
        //删除凭证
        const delImg = (item) => {
            item.imgUrl = '';
            item.imgPath = '';
        };

        //获取网银列表
        const getBankList = () => {
            proxy.$get("v3/business/front/orderPay/bankList").then(res => {
                if (res.state == 200) {
                    bankList.data = res.data ? res.data : [];
                }
            })
        };

        //切换支付方式
        const methodChange = (index, idx) => {
            clickIdx.value = index
            metIdx.value = index + idx
            payShowType.value = payMethodList.value[index].payMethodList[idx].payMethod
            current_pay_method.data = payMethodList.value[index].payMethodList[idx]
            if (payShowType.value != '20ZJ') {
                bankIndex.value = 0;
                bankAccount.value = '';
            }
            if (payShowType.value == "CREDIT") {
                state.allSuportCredit = pay_info.data.orderList.some(i => i.isSupportCredit)
                //部分订单不可支付需要将isContinue字段置为false,全部可支付的话置为true(仅在信用支付下)
                if (pay_info.data.orderList.filter(i => i.isSupportCredit).length == pay_info.data.orderList.length) {
                    isContinue.value = true
                } else {
                    isContinue.value = false
                }
            } else {
                isContinue.value = true
                state.allSuportCredit = true
                if (payShowType.value == 'wx' || payShowType.value == '42_10_ZJ' || payShowType.value == '42_20_ZJ') {
                    scanCode.value = '';
                    let param = {
                        paySn: paySn.value,
                        payMethod: payShowType.value,
                        payType: payMethodList.value[index].payMethodList[idx].payType
                    }
                    let config = {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                    // 微信、支付宝支付方式获取二维码
                    proxy.$post("v3/business/front/orderPay/doPay", param, config).then(res => {
                        if (res.state == 200) {
                            if (payShowType.value == 'wx') {
                                scanCode.value = 'data:image/png;base64,' + res.data.payData;
                            } else {
                                scanCode.value = res.data.payData;
                            }
                            // 定时查询是否支付成功
                            timerProcess.value = setInterval(() => {
                                payShowType.value == 'wx' ? queryPayState() : getPayProcess();
                            }, 3000)
                            //两小时自动跳转订单列表
                            timerTimeout.value = setTimeout(() => {
                                router.replace({
                                    path: '/member/order/list',
                                });
                            }, 432000000);
                        } else {
                            ElMessage(res.msg);
                        }
                    })
                }
            }
        };

        const isPreventRf = ref(false);
        //刷新二维码
        const refreshCode = () => {
            if (isPreventRf.value) {
                return
            }
            isPreventRf.value = true

            let index = clickIdx.value
            let idx = metIdx.value - index
            let param = {
                paySn: paySn.value,
                payMethod: payShowType.value,
                payType: payMethodList.value[index].payMethodList[idx].payType
            }
            let config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            // 微信、支付宝支付方式获取二维码
            proxy.$post("v3/business/front/orderPay/doPay", param, config).then(res => {
                isPreventRf.value = false
                if (res.state == 200) {
                    if (payShowType.value == 'wx') {
                        scanCode.value = 'data:image/png;base64,' + res.data.payData;
                    } else {
                        scanCode.value = res.data.payData;
                    }
                    // 定时查询是否支付成功
                    timerProcess.value = setInterval(() => {
                        payShowType.value == 'wx' ? queryPayState() : getPayProcess();
                    }, 3000)
                    //两小时自动跳转订单列表
                    timerTimeout.value = setTimeout(() => {
                        router.replace({
                            path: '/member/order/list',
                        });
                    }, 432000000);
                } else {
                    ElMessage(res.msg);
                }
            })
        }

        //立即付款
        const goPay = () => {
            if (isPreventClick.value) return
            isPreventClick.value = true

            let bankPayList = pay_info.data.orderList.filter(p => p.imgPath).map(i => {
                return {
                    orderSn: i.orderSn,
                    paymentVoucher: i.imgPath
                }
            })
            let paramDTO = {
                payMethod: payShowType.value,
                paySn: pay_info.data.paySn,
                payType: payShowType.value,
            }

            if (paramDTO.payMethod == 'CREDIT') {
                if (!state.allSuportCredit) {
                    isPreventClick.value = false
                    return
                }

                if (password.value == "") {
                    ElMessage.warning("请输入支付密码");
                    isPreventClick.value = false
                    return;
                }
                paramDTO.payPwd = password.value
                paramDTO.isContinue = isContinue.value
            } else if (paramDTO.payMethod == 'BANK') {
                // if (!bankPayList.length) {
                //     ElMessage('请上传凭证')
                //     isPreventClick.value = false
                //     return;
                // }
                paramDTO.bankPayList = bankPayList
            } else if (paramDTO.payMethod == '20ZJ') {
                // 企业网银支付
                if (bankIndex.value == bankList.data.length && !bankAccount.value) {
                    ElMessage('请输入银行卡号');
                    isPreventClick.value = false;
                } else {
                    let param = {
                        paySn: paySn.value,
                        payMethod: paramDTO.payMethod,
                        payType: paramDTO.payType
                    }
                    if (bankIndex.value == bankList.data.length) {
                        param.bankAccount = bankAccount.value;
                    } else {
                        param.bankCode = bankList.data[bankIndex.value].bankCode;
                    }
                    let config = {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                    proxy.$post("v3/business/front/orderPay/doPay", param, config).then(res => {
                        if (res.state == 200 && res.data.payData) {
                            window.location.href = res.data.payData;
                        } else {
                            ElMessage(res.msg);
                            isPreventClick.value = false;
                        }
                    })
                }
                return;
            } else if (paramDTO.payMethod == 'alipay') {
                paramDTO.payType = current_pay_method.data.payType;
            } else {
                isPreventClick.value = false;
                return;
            }
            proxy.$post('v3/business/front/orderPay/doPay', paramDTO, 'json').then(res => {
                if (res.state == 200) {
                    isPreventClick.value = false;
                    if (paramDTO.payMethod == 'alipay') {
                        document.write(res.data.payData);//自动提交表单数据
                    } else {

                        ElMessage.success(res.msg + ',2s后自动跳转订单列表');
                        //清除定时器
                        if (timer.value) {
                            clearTimeout(timer.value);
                            timer.value = 0;
                        }
                        setTimeout(() => {
                            router.replace({
                                path: '/member/order/list',
                            });
                        }, 2000)
                    }
                } else if (res.state == 267) {
                    //部分订单不可支付需要进行提示
                    isPreventClick.value = false
                    proxy.$confirm(res.msg, {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {
                        isContinue.value = true
                        goPay()
                    }).catch(() => {
                        metIdx.value = 0
                    })
                } else {
                    let msg = res.msg ? res.msg : '付款失败';
                    isPreventClick.value = false;
                    ElMessage.error(msg);
                }
            })
        };

        //获取支付进度
        const preventOverFlow = ref(false)
        const getPayProcess = () => {
            proxy.$get('v3/business/front/orderPay/payProcess', {
                paySn: paySn.value
            }).then(res => {
                if (res.state == 200 && res.data == 1) {
                    if (!preventOverFlow.value) {
                        ElMessage.success(res.msg + ',2s后自动跳转订单列表');
                    }
                    preventOverFlow.value = true
                    //清除定时器
                    if (timerProcess.value) {
                        clearTimeout(timerProcess.value);
                        timerProcess.value = null;
                    }
                    setTimeout(() => {
                        router.replace({
                            path: '/member/order/list',
                        });
                    }, 2000)
                }
            })
        };

        onMounted(() => {
            paySn.value = route.query.paySn;
            ifcart.value = route.query.ifcart
            getPayMethod();
            getPayInfo();
            payPwdCheck();
        });

        onUnmounted(() => {
            if (timer.value) {
                clearTimeout(timer.value);
                timer.value = 0;
                clearTimeout(timerTimeout.value);
                timerTimeout.value = 0;
            }
            if (timerProcess.value) {
                clearInterval(timerProcess.value);
                timerProcess.value = '';
                clearTimeout(timerTimeout.value);
                timerTimeout.value = 0;
            }
        });

        return {
            pay_method,
            balance,
            otherPay,
            ali,
            wechat,
            pay_info,
            current_pay_method,
            balance_list,
            other_pay_list,
            password,
            pay,
            wx_pay_tip,
            show_wx_pay,
            hideWxPay,
            refreshWxQrcode,
            wxPayQrImg,
            goRecharge,
            forgetPassword,
            showDetail,
            show_detail,
            hasPayMethod,
            configInfo,
            defaultImg,
            memberInfo,
            payComplete,
            uploadFile,
            payMethodList,
            metIdx,
            methodChange,
            payShowType,
            goPay,
            literTime,
            delImg,
            ...toRefs(state),
            toSetPwd,
            clickIdx,
            scanCode,
            bankList,
            bankIndex,
            getBankList,
            changeBank,
            bankAccount,
            refreshCode,
            timerProcess,
            timerTimeout,
            payhuoqu
        };
    }
};
</script>

<style lang="scss" scoped>
@import "../../style/pay.scss";
@import "../../style/base.scss";

.no_payMethod {
    margin: 0 auto;
    width: 1200px;
    height: 560px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
        width: 163px;
        height: 114px;
    }

    p {
        margin-top: 39px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
    }
}

.group9 {
    position: relative;
}

#upload {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    z-index: 1;
}

.img_con {
    width: 177px;
    height: 106px;
    position: relative;
    overflow: hidden;

    img {
        width: 174px;
        height: 106px;
    }

    .el-icon-error {
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 16px;
        color: #666;
        cursor: pointer;
    }
}
</style>